<template>
    <div class="height1">
        <!--      头部      -->
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :model="searchForm" :inline="true">
                            <el-form-item>
                                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions0" size="small" style="margin-right: 10px" @change="search">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="姓名"
                                    @keyup.enter.native="search" clearable ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search()">搜索</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <el-table :data="tableData" stripe style="width: 100%" height="calc(100% - 90px)" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="patient.name" label="姓名">
                    </el-table-column>
                    <!-- <el-table-column prop="patient.sex" label="性别" align="center">
                        <template slot-scope="scope">
                            <el-tag type="primary" size="small" v-if="scope.row.patient.sex == 1">男</el-tag>
                            <el-tag type="warning" size="small" v-if="scope.row.patient.sex == 0">女</el-tag>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="patient.age" label="年龄" align="center">
                    </el-table-column>
                    <el-table-column prop="patient.phone" label="手机号" align="center">
                    </el-table-column>
                    <el-table-column prop="patient.idNo" label="身份证号" align="center">
                    </el-table-column>
                    <el-table-column prop="prescriptionName" label="方剂名称" align="center">
                    </el-table-column>
                    <el-table-column prop="dosage" label="方剂数量" align="center">
                        <template slot-scope="scope">
                            <el-tag type="warning" size="small">{{ scope.row.dosage }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="addTime" label="开方时间" align="center">
                        <template slot-scope="scope"> {{ scope.row.addTime | timefilters }} </template>
                    </el-table-column>
                    <el-table-column prop="userName" label="开方人" align="center">
                    </el-table-column>
                    <!-- <el-table-column prop="organization"
                                     label="审核医生">
                    </el-table-column> -->
                    <el-table-column prop="auditTime" label="审核时间" align="center">
                        <template slot-scope="scope"> {{ scope.row.auditTime | timefilters }} </template>
                    </el-table-column>
                    <el-table-column prop="auditTime" label="取药时间" align="center">
                        <template slot-scope="scope"> {{ scope.row.takeTime | timefilters }} </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" align="center">
                        <template slot-scope="scope">
                            <span class="success optionBtn" @click="openDetails(scope.row)">详情
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
        <el-drawer title="处方详情" :visible.sync="detaildrawer" :direction="direction" wrapperClosable>
            <el-descriptions class="margin-top" :column="1" :size="size" border>
                <el-descriptions-item label="患者信息">{{ patientDetail.name }}（{{ patientDetail.sex == false ? '女' : '男' }}
                    {{ patientDetail.age }}岁） {{ patientDetail.phone }}</el-descriptions-item>
                <el-descriptions-item label="地址">{{ patientDetail.address }}</el-descriptions-item>
                <el-descriptions-item label="主诉">{{ DiagnoseDetial.main }}</el-descriptions-item>
                <el-descriptions-item label="诊断">{{ DiagnoseDetial.diseaseDiagnose }}</el-descriptions-item>
                <el-descriptions-item label="既往史">{{ DiagnoseDetial.past }}</el-descriptions-item>
            </el-descriptions>
            <el-divider></el-divider>
            <h3>{{ detailData.prescriptionName }}</h3>
            <el-table :data="detailData.patientPrescriptionList" stripe style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                <el-table-column prop="patientComposition.compositionName" label="成分" width="180">
                </el-table-column>
                <el-table-column prop="dosage" label="用量" width="180">
                </el-table-column>
                <el-table-column prop="remark" label="炮制方法">
                </el-table-column>
            </el-table>
            <el-divider></el-divider>
            <el-descriptions class="margin-top" :column="1" :size="size">
                <el-descriptions-item label="剂量">{{ detailData.dosage }}剂</el-descriptions-item>
                <el-descriptions-item label="快递方式">{{ detailData.isSelef == true ? '自取' : '快递' }}</el-descriptions-item>
                <el-descriptions-item label="备注">{{ detailData.remark }}</el-descriptions-item>
            </el-descriptions>
        </el-drawer>
    </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb";
import { Prescription } from "../../../components/HospitalDomain/Patient/Prescription";
import { Patient } from "../../../components/HospitalDomain/Patient/Patient";

export default {
    name: "Completed",
    components: {
        bread
    },
    data() {
        var patient = new Patient(this.TokenClient, this.Services.Authorization)
        var prescription = new Prescription(this.TokenClient, this.Services.Drug)
        return {
            patientDomain: patient,
            PrescriptionDomain: prescription,
            organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
            tableData: [
                {
                    id: 1,
                    name: '张三',
                    sex: 1,
                    age: 48,
                    phone: '13288885555',
                    idCard: '410881199605052121',
                    drugName: '大庆弄堂',
                    drugNum: 3,
                    createTime: '2021-07-07T18:18:08.332989',
                    status: 0,
                    operator: '李四'
                }
            ],
            searchForm: {
                statrTime: '',
                endTime: '',
                keyWord: '',
                datepicker: ''
            },
            auditStatus: 3,
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 15,
            DataTotal: 0,
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            detaildrawer: false,
            direction: 'rtl',
            detailData: {},
            patientDetail: {},
            DiagnoseDetial: {},
            size: 'middle',
            patientList: [],
            userList:JSON.parse(sessionStorage.getItem('UserSession'))
        }
    },
mounted() {
        this.GetList();
        // 查询所有患者信息
        // this.getPatients()
    },
    methods: {
        getPatients() {
            this.patientDomain.Patients((data) => {
                this.patientList = data.data
                 this.GetList();
            }, (err) => {
                console.log(err)
            });
        },
        search() {
            this.PageIndex = 1;
            this.GetList();
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.GetList();
        },
        GetList() {
            var _this = this;
            _this.tableData = [];
            var item = _this.searchForm;
            if (_this.searchForm.datepicker) {
                item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
            } else {
                item.startTime = "";
                item.endTime = "";
            }
            _this.PrescriptionDomain.prescriptionList('-999', '0', _this.organizationId, _this.auditStatus, item.startTime, item.endTime, item.keyWord, _this.PageIndex,
                function (data) {
                    _this.tableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.DataTotal = data.data.dataTotal;
                    // if (_this.tableData && _this.tableData.length > 0) {
                    //     _this.tableData.forEach((data) => {
                    //         let patientInfo = _this.patientList.filter((item) => item.id == data.patientId)
                    //         if (patientInfo.length > 0) {
                    //             data.name = patientInfo[0].name
                    //             data.sex = patientInfo[0].sex
                    //             data.age = patientInfo[0].age
                    //             data.phone = patientInfo[0].phone
                    //             data.idCard = patientInfo[0].idNo
                    //         }
                    //         let userInfo = _this.userList.filter((item1) => item1.id == data.userId)
                    //         if (userInfo.length > 0) {
                    //             data.userName = userInfo[0].userName
                    //         }
                    //     })
                    // }
                },
                function (error) {
                    console.log(error);
                });
        },
        handelFinish(item) {
            var _this = this;
            this.$confirm('备药已完成?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.PrescriptionDomain.FinishPrescription(item.id,
                    function (data) {
                        _this.$message({
                            type: 'success',
                            message: '完成备药!'
                        });
                        _this.GetList();
                    },
                    function (error) {
                        console.log(error);
                    });
            }).catch(() => {
                _this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        openDetails(row) {
            var _this = this;
            _this.getDetail(row.id);
            _this.getPatientDetail(row.patientId);
            _this.getcur(row.patientDiagnoseId);
            _this.detaildrawer = true
        },
        getDetail(id) {
            var _this = this;
            _this.PrescriptionDomain.GetDetail(id,
                function (data) {
                    _this.detailData = data.data

                },
                function (error) {
                    console.log(error);
                });
        },
        getPatientDetail(id) {
            var _this = this;
            _this.patientDomain.GetDetail(id,
                function (data) {
                    _this.patientDetail = data.data;
                },
                function (err) {

                });
        },
        getcur(id) {
            var _this = this;
            _this.patientDomain.DiagnoseDetial(id,
                function (data) {
                    _this.DiagnoseDetial = data.data;
                },
                function (err) {

                });

        },
    }
}
</script>

<style scoped>
/*content*/


.table-top {
    /* margin-bottom: 15px; */
}
</style>
<style>
.el-drawer {
    background-color: #ffffff !important;
    width: 550px !important;
}

.el-drawer__body {
    overflow: auto;
    padding: 20px;
}
</style>
