let Prescription = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

    /**
     *
     * @param prescriptionType 方剂类型 1个人 2机构 3总后台
     * @param userId 机构 userId=0
     * @param organitionId 个人 OrganitionId=0
     * @param auditStatus 审核状态：-1：驳回、0：待审核、1：审核通过、2：待取药、3：完成
     * @param startTime
     * @param endTime
     * @param keyWord
     * @param pageIndex
     * @param success
     * @param error
     */
    this.prescriptionList = function (prescriptionType, userId, organitionId, auditStatus, startTime, endTime, keyWord, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/PatientPrescription/GetState';
        if (prescriptionType) {
            url += `/${prescriptionType}`;
        }
        if (userId) {
            url += `/${userId}`;
        }
        if (organitionId) {
            url += `/${organitionId}`;
        }
        if (auditStatus) {
            url += `/${auditStatus}`;
        }
        if (startTime) {
            url += `/${startTime}`;
        } else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        } else {
            url += '/%20';
        }
        if (keyWord) {
            url += `/${keyWord}`;
        } else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 获取待审核和审核通过数据
     * @param orgId
     * @param startTime
     * @param endTime
     * @param keyWord
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.AuditPrescriptionList = function (orgId,startTime, endTime, keyWord, pageIndex,pageSize, success, error) {
        var url = ServiceBaseAddress + '/api/PatientPrescription/GetStateOrg';
        if (orgId) {
            url += `/${orgId}`;
        } else {
            url += '/%20';
        }if (startTime) {
            url += `/${startTime}`;
        } else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        } else {
            url += '/%20';
        }
        if (keyWord) {
            url += `/${keyWord}`;
        } else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (pageSize) {
            url += `/${pageSize}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 处方详情
     * @param patientPrescriptionId
     * @param success
     * @param error
     * @constructor
     */
    this.GetDetail = function (patientPrescriptionId, success, error) {
        var url = ServiceBaseAddress + '/api/PatientPrescription/GetDetail/'+patientPrescriptionId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }


    this.FinishPrescription = function (patientPrescriptionId, type, success, error) {
        var url = ServiceBaseAddress + '/api/PatientPrescription/FinishPrescription/'+patientPrescriptionId + '/'+type;
        TokenClient.Put(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}
export { Prescription }
